import { call, put, takeEvery } from 'redux-saga/effects';
import {
  forgotUserPassword,
  login,
  registerUser,
  requestLogOut,
  resetCustomerPassword,
  resetUserPassword,
} from 'modules/auth/actions';
import { User } from 'models/user';
import {
  postForgotUserPassword,
  postLogin,
  postRegistration,
  postResetCustomerPassword,
  postResetUserPassword,
} from 'modules/auth/services';
import { APIResponse } from 'models/request';
import { eraseUserToken, saveUserToken } from 'utils/storage/localStorage';
import handleError from 'api/handleError';
import { history } from 'store';
import { Feature } from 'models/feature';

type LoginResponse = {
  features: Feature[];
  token: string;
  user: User;
};

function* loginSaga({ payload }: ReturnType<typeof login.request>): Generator {
  try {
    const { data }: APIResponse<LoginResponse> = (yield call(
      postLogin,
      payload,
    )) as APIResponse<LoginResponse>;

    saveUserToken(data.token);

    yield put(login.success({ ...data.user, features: data.features }));
  } catch (err) {
    const { data } = handleError(err);

    yield put(login.failure(data));
  }
}

function logoutSaga(): void {
  eraseUserToken();
  history.push('/login');
}

function* registerUserSaga({
  payload,
}: ReturnType<typeof registerUser.request>) {
  try {
    yield call(postRegistration, payload);

    yield put(registerUser.success());
  } catch (err) {
    const { data } = handleError(err);

    yield put(registerUser.failure(data));
  }
}

function* resetCustomerPasswordSaga({
  payload,
}: ReturnType<typeof resetCustomerPassword.request>) {
  try {
    yield call(postResetCustomerPassword, payload);

    yield put(resetCustomerPassword.success());
  } catch (err) {
    const { data } = handleError(err);

    yield put(resetCustomerPassword.failure(data));
  }
}

function* forgotUserPasswordSaga({
  payload,
}: ReturnType<typeof forgotUserPassword.request>) {
  try {
    yield call(postForgotUserPassword, payload);

    yield put(forgotUserPassword.success());
  } catch (err) {
    const { data } = handleError(err);

    yield put(forgotUserPassword.failure(data));
  }
}

function* resetUserPasswordSaga({
  payload,
}: ReturnType<typeof resetUserPassword.request>) {
  try {
    yield call(postResetUserPassword, payload);

    yield put(resetUserPassword.success());
  } catch (err) {
    const { data } = handleError(err);

    yield put(resetUserPassword.failure(data));
  }
}

export default [
  takeEvery(forgotUserPassword.request, forgotUserPasswordSaga),
  takeEvery(login.request, loginSaga),
  takeEvery(requestLogOut, logoutSaga),
  takeEvery(registerUser.request, registerUserSaga),
  takeEvery(resetCustomerPassword.request, resetCustomerPasswordSaga),
  takeEvery(resetUserPassword.request, resetUserPasswordSaga),
];
