import { call, put, takeEvery } from 'redux-saga/effects';
import { APIListResponse, APIResponse } from 'models/request';
import { Platform } from 'models/platform';
import {
  createPlatform,
  deletePlatform,
  fetchPlatform,
  listPlatforms,
  updatePlatform,
} from './actions';
import {
  delPlatform,
  getPlatform,
  getPlatforms,
  patchPlatform,
  postPlatform,
} from './services';
import handleError from 'api/handleError';
import sellerCategorySagas from './sellerCategory/sagas';

function* listPlatformsSaga({
  payload,
}: ReturnType<typeof listPlatforms.request>): Generator {
  try {
    const {
      data: { count, list },
    }: APIListResponse<Platform> = (yield call(
      getPlatforms,
      payload,
    )) as APIListResponse<Platform>;

    yield put(listPlatforms.success({ count, list }));
  } catch (err) {
    const { data } = handleError(err);

    yield put(listPlatforms.failure(data));
  }
}

function* fetchPlatformSaga({
  payload,
}: ReturnType<typeof fetchPlatform.request>): Generator {
  try {
    const { data }: APIResponse<Platform> = (yield call(
      getPlatform,
      payload,
    )) as APIResponse<Platform>;

    yield put(fetchPlatform.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(fetchPlatform.failure(data));
  }
}

function* createPlatformSaga({
  payload,
}: ReturnType<typeof createPlatform.request>): Generator {
  try {
    const { data }: APIResponse<Platform> = (yield call(
      postPlatform,
      payload,
    )) as APIResponse<Platform>;

    yield put(createPlatform.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(createPlatform.failure(data));
  }
}

function* updatePlatformSaga({
  payload,
}: ReturnType<typeof updatePlatform.request>): Generator {
  try {
    const { data }: APIResponse<Platform> = (yield call(
      patchPlatform,
      payload,
    )) as APIResponse<Platform>;

    yield put(updatePlatform.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(updatePlatform.failure(data));
  }
}

function* deletePlatformSaga({
  payload,
}: ReturnType<typeof deletePlatform.request>): Generator {
  try {
    yield call(delPlatform, payload);

    yield put(deletePlatform.success(payload));
  } catch (err) {
    const { data } = handleError(err);

    yield put(deletePlatform.failure(data));
  }
}

export default [
  takeEvery(listPlatforms.request, listPlatformsSaga),
  takeEvery(fetchPlatform.request, fetchPlatformSaga),
  takeEvery(createPlatform.request, createPlatformSaga),
  takeEvery(updatePlatform.request, updatePlatformSaga),
  takeEvery(deletePlatform.request, deletePlatformSaga),
  ...sellerCategorySagas,
];
