import handleError from 'api/handleError';
import { APIListResponse, APIResponse } from 'models/request';
import { Seller } from 'models/seller';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createSeller,
  deleteSeller,
  fetchSeller,
  listSellers,
  updateSeller,
  updateSellerIsDeliverable,
  updateSellerWorkingHours,
} from './actions';
import {
  delSeller,
  getSeller,
  getSellers,
  patchSeller,
  patchSellerWorkingHours,
  patchSellerIsDeliverable,
  postSeller,
  uploadAvatar,
  uploadCover,
} from './services';
import categorySagas from './productCategory/sagas';

function* listSellersSaga({
  payload,
}: ReturnType<typeof listSellers.request>): Generator {
  try {
    const {
      data: { count, list },
    }: APIListResponse<Seller> = (yield call(
      getSellers,
      payload,
    )) as APIListResponse<Seller>;

    yield put(listSellers.success({ count, list }));
  } catch (err) {
    const { data } = handleError(err);

    yield put(listSellers.failure(data));
  }
}

function* fetchSellerSaga({
  payload,
}: ReturnType<typeof fetchSeller.request>): Generator {
  try {
    const { data }: APIResponse<Seller> = (yield call(
      getSeller,
      payload,
    )) as APIResponse<Seller>;

    yield put(fetchSeller.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(fetchSeller.failure(data));
  }
}

function* createSellerSaga({
  payload,
}: ReturnType<typeof createSeller.request>): Generator {
  const { avatar, coverImage, ...rest } = payload;

  try {
    const { data: newSeller }: APIResponse<Seller> = (yield call(postSeller, {
      ...rest,
    })) as APIResponse<Seller>;

    if (avatar) {
      const { data }: APIResponse<Seller> = (yield call(uploadAvatar, {
        image: avatar,
        sellerID: newSeller.id,
      })) as APIResponse<Seller>;

      newSeller.avatar = data.avatar;
      newSeller.avatarUrl = data.avatarUrl;
    }

    if (coverImage) {
      const { data }: APIResponse<Seller> = (yield call(uploadCover, {
        image: coverImage,
        sellerID: newSeller.id,
      })) as APIResponse<Seller>;

      newSeller.coverImage = data.coverImage;
      newSeller.coverImageUrl = data.coverImageUrl;
    }

    yield put(createSeller.success(newSeller));
  } catch (err) {
    const { data } = handleError(err);

    yield put(createSeller.failure(data));
  }
}

function* updateSellerSaga({
  payload,
}: ReturnType<typeof updateSeller.request>): Generator {
  const {
    avatar,
    avatarUrl,
    newAvatarUrl,
    coverImage,
    coverImageUrl,
    newCoverImageUrl,
    ...rest
  } = payload;

  try {
    const { data: updatedSeller }: APIResponse<Seller> = (yield call(
      patchSeller,
      {
        ...rest,
      },
    )) as APIResponse<Seller>;

    if (avatar || newAvatarUrl !== avatarUrl) {
      const { data }: APIResponse<Seller> = (yield call(uploadAvatar, {
        image: avatar,
        sellerID: updatedSeller.id,
      })) as APIResponse<Seller>;

      updatedSeller.avatar = data.avatar;
      updatedSeller.avatarUrl = data.avatarUrl;
    }

    if (coverImage || newCoverImageUrl !== coverImageUrl) {
      const { data }: APIResponse<Seller> = (yield call(uploadCover, {
        image: coverImage,
        sellerID: updatedSeller.id,
      })) as APIResponse<Seller>;

      updatedSeller.coverImage = data.coverImage;
      updatedSeller.coverImageUrl = data.coverImageUrl;
    }

    yield put(updateSeller.success(updatedSeller));
  } catch (err) {
    const { data } = handleError(err);

    yield put(updateSeller.failure(data));
  }
}

function* updateSellerWorkingHoursSaga({
  payload,
}: ReturnType<typeof updateSellerWorkingHours.request>): Generator {
  try {
    const { data }: APIResponse<Seller> = (yield call(
      patchSellerWorkingHours,
      payload,
    )) as APIResponse<Seller>;

    yield put(updateSellerWorkingHours.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(updateSellerWorkingHours.failure(data));
  }
}

function* updateSellerIsDeliverableSaga({
  payload,
}: ReturnType<typeof updateSellerIsDeliverable.request>): Generator {
  try {
    const { data }: APIResponse<Seller> = (yield call(
      patchSellerIsDeliverable,
      payload,
    )) as APIResponse<Seller>;

    yield put(updateSellerIsDeliverable.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(updateSellerIsDeliverable.failure(data));
  }
}

function* deleteSellerSaga({
  payload,
}: ReturnType<typeof deleteSeller.request>): Generator {
  try {
    yield call(delSeller, payload);

    yield put(deleteSeller.success(payload));
  } catch (err) {
    const { data } = handleError(err);

    yield put(deleteSeller.failure(data));
  }
}

export default [
  takeEvery(listSellers.request, listSellersSaga),
  takeEvery(fetchSeller.request, fetchSellerSaga),
  takeEvery(createSeller.request, createSellerSaga),
  takeEvery(updateSeller.request, updateSellerSaga),
  takeEvery(updateSellerWorkingHours.request, updateSellerWorkingHoursSaga),
  takeEvery(updateSellerIsDeliverable.request, updateSellerIsDeliverableSaga),
  takeEvery(deleteSeller.request, deleteSellerSaga),
  ...categorySagas,
];
