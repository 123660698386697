import { del, get, patch, post } from 'api';
import { Patch, Unregistered } from 'models/generic';
import { ProductCategory } from 'models/seller/productCategory';

export const getProductCategoriesBySeller = (id: string) =>
  get({ url: `/product-category/load-by-seller/${id}` });

export const getProductCategory = (id: string) =>
  get({ url: `/product-category/${id}` });

export const postProductCategory = (data: Unregistered<ProductCategory>) =>
  post({ data, url: '/product-category' });

export const patchProductCategory = (data: Patch<ProductCategory>) =>
  patch({ data, url: '/product-category' });

export const delProductCategory = (id: string) =>
  del({ url: `/product-category/${id}` });
