import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '3px',
        textTransform: 'capitalize',
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 1400,
      },
    },
  },
  palette: {
    background: {
      default: '#ebeff2',
    },
    primary: {
      main: '#383838',
    },
    secondary: {
      main: '#000000',
    },
  },
});
