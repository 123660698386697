import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { sellerCategory as sellerCategoryReducer } from './sellerCategory/reducer';
import { RequestStatus } from 'models/request';
import {
  createPlatform,
  deletePlatform,
  fetchPlatform,
  listPlatforms,
  resetFetchedPlatformData,
  resetPlatformOperation,
  updatePlatform,
} from './actions';
import { Platform } from 'models/platform';
import { IntegratedListState, IntegratedState } from 'models/state';

const initialListState: IntegratedListState<Platform[]> = {
  count: 0,
  data: [],
  error: undefined,
  status: RequestStatus.IDLE,
};

const listReducer = createReducer(initialListState)
  .handleAction(listPlatforms.request, () => ({
    ...initialListState,
    status: RequestStatus.FETCHING,
  }))
  .handleAction(listPlatforms.success, (state, action) => ({
    ...state,
    count: action.payload.count,
    data: [...action.payload.list],
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(listPlatforms.failure, (state, action) => ({
    ...initialListState,
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(
    [createPlatform.success, fetchPlatform.success],
    (state, action) => ({
      ...state,
      count: state.count + 1,
      data: [...state.data, action.payload],
    }),
  )
  .handleAction(updatePlatform.success, (state, action) => ({
    ...state,
    data: state.data.map((user) =>
      user.id === action.payload.id ? { ...user, ...action.payload } : user,
    ),
  }))
  .handleAction(deletePlatform.success, (state, action) => ({
    ...state,
    count: state.count - 1,
    data: state.data.filter((user) => user.id !== action.payload),
  }));

const initialOperationState: IntegratedState<Platform | null> = {
  data: null,
  error: undefined,
  status: RequestStatus.IDLE,
};

const operationReducer = createReducer(initialOperationState)
  .handleAction(
    [createPlatform.request, updatePlatform.request, deletePlatform.request],
    () => ({
      ...initialOperationState,
      status: RequestStatus.FETCHING,
    }),
  )
  .handleAction(
    [createPlatform.success, updatePlatform.success],
    (state, action) => ({
      ...state,
      data: action.payload,
      status: RequestStatus.SUCCESS,
    }),
  )
  .handleAction(deletePlatform.success, (state) => ({
    ...state,
    data: null,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(
    [createPlatform.failure, updatePlatform.failure, deletePlatform.failure],
    (state, action) => ({
      ...initialOperationState,
      error: {
        ...action.payload,
      },
      status: RequestStatus.FAILURE,
    }),
  )
  .handleAction(resetPlatformOperation, () => ({
    ...initialOperationState,
  }));

const initialRequestPlatformState: IntegratedState<Platform | null> = {
  data: null,
  error: undefined,
  status: RequestStatus.IDLE,
};

const requestedPlatformReducer = createReducer(initialRequestPlatformState)
  .handleAction(fetchPlatform.request, () => ({
    ...initialRequestPlatformState,
    status: RequestStatus.FETCHING,
  }))
  .handleAction(fetchPlatform.success, (state, action) => ({
    ...state,
    data: action.payload,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(fetchPlatform.failure, (state, action) => ({
    ...initialRequestPlatformState,
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(resetFetchedPlatformData, () => ({
    ...initialRequestPlatformState,
  }));

export const platform = combineReducers({
  categories: sellerCategoryReducer,
  list: listReducer,
  operation: operationReducer,
  requestedPlatform: requestedPlatformReducer,
});
