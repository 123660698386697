import { call, put, takeEvery } from 'redux-saga/effects';
import { APIListResponse, APIResponse } from 'models/request';
import handleError from 'api/handleError';
import {
  createFeatureGroup,
  deleteFeatureGroup,
  listFeatureGroups,
  listFeatures,
  updateFeatureGroup,
} from 'modules/feature/actions';
import { Feature, FeatureGroup } from 'models/feature';
import {
  delFeatureGroup,
  getFeatureGroups,
  getFeatures,
  patchFeatureGroup,
  postFeatureGroup,
} from 'modules/feature/services';

function* listFeaturesSaga({
  payload,
}: ReturnType<typeof listFeatures.request>): Generator {
  try {
    const { data }: APIListResponse<Feature> = (yield call(
      getFeatures,
      payload,
    )) as APIListResponse<Feature>;

    yield put(listFeatures.success({ count: data.count, list: data.list }));
  } catch (err) {
    const { data } = handleError(err);

    yield put(listFeatures.failure(data));
  }
}

function* listFeatureGroupsSaga({
  payload,
}: ReturnType<typeof listFeatureGroups.request>): Generator {
  try {
    const { data }: APIListResponse<FeatureGroup> = (yield call(
      getFeatureGroups,
      payload,
    )) as APIListResponse<FeatureGroup>;

    yield put(
      listFeatureGroups.success({ count: data.count, list: data.list }),
    );
  } catch (err) {
    const { data } = handleError(err);

    yield put(listFeatureGroups.failure(data));
  }
}

function* createFeatureGroupSaga({
  payload,
}: ReturnType<typeof createFeatureGroup.request>): Generator {
  try {
    const { data }: APIResponse<FeatureGroup> = (yield call(
      postFeatureGroup,
      payload,
    )) as APIResponse<FeatureGroup>;

    yield put(createFeatureGroup.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(createFeatureGroup.failure(data));
  }
}

function* updateFeatureGroupSaga({
  payload,
}: ReturnType<typeof updateFeatureGroup.request>): Generator {
  try {
    const { data }: APIResponse<FeatureGroup> = (yield call(
      patchFeatureGroup,
      payload,
    )) as APIResponse<FeatureGroup>;

    yield put(updateFeatureGroup.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(updateFeatureGroup.failure(data));
  }
}

function* deleteFeatureGroupSaga({
  payload,
}: ReturnType<typeof deleteFeatureGroup.request>): Generator {
  try {
    yield call(delFeatureGroup, payload);

    yield put(deleteFeatureGroup.success(payload));
  } catch (err) {
    const { data } = handleError(err);

    yield put(deleteFeatureGroup.failure(data));
  }
}

export default [
  takeEvery(listFeatures.request, listFeaturesSaga),
  takeEvery(listFeatureGroups.request, listFeatureGroupsSaga),
  takeEvery(createFeatureGroup.request, createFeatureGroupSaga),
  takeEvery(updateFeatureGroup.request, updateFeatureGroupSaga),
  takeEvery(deleteFeatureGroup.request, deleteFeatureGroupSaga),
];
