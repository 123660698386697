import React, { FC, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import PrivateRoute from 'components/routing/PrivateRoute/PrivateRoute';
import LoadingOverlay from 'components/loaders/LoadingOverlay/LoadingOverlay';

const Login = lazy(() => import('./Login/Login'));
const SignUp = lazy(() => import('./SignUp/SignUp'));
const ResetPassword = lazy(() => import('./ResetPassword/ResetPassword'));
const Dashboard = lazy(() => import('./Dashboard/Dashboard'));
const ForgotPassword = lazy(() => import('./ForgotPassword/ForgotPassword'));

const App: FC = () => (
  <Suspense fallback={<LoadingOverlay />}>
    <Switch>
      <Route path={'/login'} component={Login} />
      <Route path={'/sign-up/:token'} component={SignUp} />
      <Route path={'/users/reset-password'} component={ResetPassword} />
      <Route path={'/customers/reset-password'} component={ResetPassword} />
      <Route path={'/forgot-password'} component={ForgotPassword} />
      <PrivateRoute path={'/'} component={Dashboard} />
    </Switch>
  </Suspense>
);

export default App;
