const TOKEN_KEY = 'auth@jhsf';

export const saveUserToken = (token: string): void => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const retrieveUserToken = (): string =>
  localStorage.getItem(TOKEN_KEY) || '';

export const eraseUserToken = (): void => localStorage.removeItem(TOKEN_KEY);
