import { TreeView } from 'models/generic';
import { SellerCategory } from 'models/platform/sellerCategory';
import { ProductCategory } from 'models/seller/productCategory';

const insertIntoDataList = (
  original: TreeView<SellerCategory | ProductCategory>[],
  value: TreeView<SellerCategory | ProductCategory>,
): TreeView<SellerCategory | ProductCategory>[] => {
  const result = [...original];

  if (!value.parent) {
    result.push(value);
  } else {
    result.forEach((item) => {
      if (item.id === value.parent) {
        item.children = item.children ? [...item.children, value] : [value];
      } else if (item.children && item.children.length) {
        item.children = insertIntoDataList(item.children, value);
      }
    });
  }

  return result;
};

export default insertIntoDataList;
