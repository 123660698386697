import isSameArray from 'utils/comparison/isSameArray';

type Param = Record<string, unknown> | null;

const isSameObject = (original: Param, target: Param): boolean => {
  let isEqual = true;

  if (!original || !target) {
    isEqual = false;
  } else {
    isEqual =
      isSameArray(Object.keys(original), Object.keys(target)) &&
      isSameArray(Object.values(original), Object.values(target));
  }

  return isEqual;
};

export default isSameObject;
