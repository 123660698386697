import { del, patch, post } from 'api';
import { PaginationPayload } from 'models/request';
import { Patch, Unregistered } from 'models/generic';
import { User } from 'models/user';

export const getUsers = (data: PaginationPayload) =>
  post({ data, url: '/users' });

export const postUser = (data: Unregistered<User>) =>
  post({ data, url: '/user' });

export const patchUser = (data: Patch<User>) => patch({ data, url: '/user' });

export const delUser = (id: string) => del({ url: `/user/${id}` });
