import { del, get, patch, post } from 'api';
import { Patch, Unregistered } from 'models/generic';
import { SellerCategory } from 'models/platform/sellerCategory';

export const getSellerCategoriesByPlatform = (id: string) =>
  get({ url: `/seller-category/load-by-platform/${id}` });

export const getSellerCategory = (id: string) =>
  get({ url: `/seller-category/${id}` });

export const postSellerCategory = (data: Unregistered<SellerCategory>) =>
  post({ data, url: '/seller-category' });

export const patchSellerCategory = (data: Patch<SellerCategory>) =>
  patch({ data, url: '/seller-category' });

export const delSellerCategory = (id: string) =>
  del({ url: `/seller-category/${id}` });
