import { connectRouter } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { auth as authReducer } from 'modules/auth/reducer';
import { customer as customerReducer } from 'modules/customer/reducer';
import { feature as featureReducer } from 'modules/feature/reducer';
import { platform as platformReducer } from 'modules/platform/reducer';
import { seller as sellerReducer } from 'modules/seller/reducer';
import { ui as uiReducer } from 'modules/ui/reducer';
import { user as userReducer } from 'modules/user/reducer';
import { combineReducers } from 'redux';

export const history: History = createBrowserHistory();

const rootReducer = combineReducers({
  auth: authReducer,
  customer: customerReducer,
  feature: featureReducer,
  platform: platformReducer,
  router: connectRouter(history),
  seller: sellerReducer,
  ui: uiReducer,
  user: userReducer,
});

export default rootReducer;
