/* eslint-disable no-console */

import { RootState } from 'store/state';
import { PersistConfig } from 'redux-persist/es/types';
import isSameArray from 'utils/comparison/isSameArray';

export default (
  inboundState: RootState,
  originalState: RootState,
  reducedState: RootState,
  { debug }: PersistConfig<RootState>,
): RootState => {
  let newState = { ...reducedState };

  if (inboundState && typeof inboundState === 'object') {
    Object.keys(inboundState).forEach((key) => {
      if (key === '_persist') {
        return;
      }

      const reducedKey = key as keyof typeof reducedState;

      const originalEntry = originalState[key as keyof typeof originalState];
      const inboundEntry = inboundState[key as keyof typeof inboundState];

      if (
        typeof originalEntry === 'object' &&
        typeof inboundEntry === 'object'
      ) {
        const originalKeys = Object.keys(originalEntry).filter(
          (key) => key !== 'error',
        );
        const inboundKeys = Object.keys(inboundEntry).filter(
          (key) => key !== 'error',
        );

        if (!isSameArray(originalKeys, inboundKeys)) {
          if (debug) console.log(originalEntry);
          console.warn(
            `redux-persist/stateReconciler: sub state for key '${key}' modified, skipping.`,
          );
          newState = { ...newState, [reducedKey]: originalEntry };
          return;
        }
      }

      newState = { ...newState, [reducedKey]: inboundEntry };
    });
  }

  return newState;
};
