import { TreeView } from 'models/generic';
import { SellerCategory } from 'models/platform/sellerCategory';
import { ProductCategory } from 'models/seller/productCategory';

const updateDataList = (
  original: TreeView<SellerCategory | ProductCategory>[],
  value: TreeView<SellerCategory | ProductCategory>,
): TreeView<SellerCategory | ProductCategory>[] =>
  original.map((item) => {
    if (item.id === value.id) {
      return { ...value, updated: true };
    }

    if (item.children && item.children.length) {
      return {
        ...item,
        children: updateDataList(item.children, value),
      };
    }

    return item;
  });

export default updateDataList;
