import { createAction, createAsyncAction } from 'typesafe-actions';
import { GenericError, PaginationPayload } from 'models/request';
import { Paginated, Patch, Unregistered } from 'models/generic';
import { Platform } from 'models/platform';

export const listPlatforms = createAsyncAction(
  '@@admin/PLATFORM_LIST_REQUEST',
  '@@admin/PLATFORM_LIST_REQUEST_SUCCESS',
  '@@admin/PLATFORM_LIST_REQUEST_FAILURE',
)<PaginationPayload, Paginated<Platform[]>, GenericError>();

export const fetchPlatform = createAsyncAction(
  '@@admin/FETCH_PLATFORM_REQUEST',
  '@@admin/FETCH_PLATFORM_REQUEST_SUCCESS',
  '@@admin/FETCH_PLATFORM_REQUEST_FAILURE',
)<string, Platform, GenericError>();

export const createPlatform = createAsyncAction(
  '@@admin/CREATE_PLATFORM_REQUEST',
  '@@admin/CREATE_PLATFORM_REQUEST_SUCCESS',
  '@@admin/CREATE_PLATFORM_REQUEST_FAILURE',
)<Unregistered<Platform>, Platform, GenericError>();

export const updatePlatform = createAsyncAction(
  '@@admin/UPDATE_PLATFORM_REQUEST',
  '@@admin/UPDATE_PLATFORM_REQUEST_SUCCESS',
  '@@admin/UPDATE_PLATFORM_REQUEST_FAILURE',
)<Patch<Platform>, Platform, GenericError>();

export const deletePlatform = createAsyncAction(
  '@@admin/DELETE_PLATFORM_REQUEST',
  '@@admin/DELETE_PLATFORM_REQUEST_SUCCESS',
  '@@admin/DELETE_PLATFORM_REQUEST_FAILURE',
)<string, string, GenericError>();

export const resetPlatformOperation = createAction(
  '@@admin/RESET_PLATFORM_OPERATION_STATUS',
)();

export const resetFetchedPlatformData = createAction(
  '@@admin/RESET_FETCHED_PLATFORM_DATA',
)();
