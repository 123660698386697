import { createAction, createAsyncAction } from 'typesafe-actions';
import { GenericError, PaginationPayload } from 'models/request';
import { Paginated, Patch, Unregistered } from 'models/generic';
import { Feature, FeatureGroup } from 'models/feature';

export const listFeatures = createAsyncAction(
  '@@admin/LIST_FEATURES_REQUEST',
  '@@admin/LIST_FEATURES_REQUEST_SUCCESS',
  '@@admin/LIST_FEATURES_FAILURE',
)<PaginationPayload, Paginated<Feature[]>, GenericError>();

export const listFeatureGroups = createAsyncAction(
  '@@admin/LIST_FEATURE_GROUPS_REQUEST',
  '@@admin/LIST_FEATURE_GROUPS_REQUEST_SUCCESS',
  '@@admin/LIST_FEATURE_GROUPS_FAILURE',
)<PaginationPayload, Paginated<FeatureGroup[]>, GenericError>();

export const createFeatureGroup = createAsyncAction(
  '@@admin/CREATE_FEATURE_GROUP_REQUEST',
  '@@admin/CREATE_FEATURE_GROUP_REQUEST_SUCCESS',
  '@@admin/CREATE_FEATURE_GROUP_FAILURE',
)<Unregistered<FeatureGroup>, FeatureGroup, GenericError>();

export const updateFeatureGroup = createAsyncAction(
  '@@admin/UPDATE_FEATURE_GROUP_REQUEST',
  '@@admin/UPDATE_FEATURE_GROUP_REQUEST_SUCCESS',
  '@@admin/UPDATE_FEATURE_GROUP_FAILURE',
)<Patch<FeatureGroup>, FeatureGroup, GenericError>();

export const deleteFeatureGroup = createAsyncAction(
  '@@admin/DELETE_FEATURE_GROUP_REQUEST',
  '@@admin/DELETE_FEATURE_GROUP_REQUEST_SUCCESS',
  '@@admin/DELETE_FEATURE_GROUP_FAILURE',
)<string, string, GenericError>();

export const resetFeatureGroupOperationData = createAction(
  '@@admin/RESET_FEATURE_GROUP_OPERATIONS',
)();
