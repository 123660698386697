import { all } from 'redux-saga/effects';

import authSagas from 'modules/auth/sagas';
import customerSagas from 'modules/customer/sagas';
import featureSagas from 'modules/feature/sagas';
import platformSagas from 'modules/platform/sagas';
import userSagas from 'modules/user/sagas';
import sellerSagas from 'modules/seller/sagas';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...customerSagas,
    ...featureSagas,
    ...platformSagas,
    ...userSagas,
    ...sellerSagas,
  ]);
}
