const appErrorType = [
  {
    message: 'seller-is-closed',
    pt: 'Restaurante fechado.',
  },
  {
    message: 'seller-is-not-delivering',
    pt: 'O restaurante não está entregando no momento.',
  },
  {
    message: 'seller-not-found',
    pt: 'Restaurante não encontrado.',
  },
  {
    message: 'seller-document-already-registered',
    pt: 'Este documento já esta sendo usado.',
  },
  {
    message: 'missing-seller-feature-group-to-assign',
    pt: 'Por favor, crie as permissões antes de criar um usuário.',
  },
  {
    message: 'product-not-found',
    pt: 'Produto não encontrado.',
  },
  {
    message: 'products-import-error',
    pt: 'Nenhum produto criado, por favor verifque se este arquivo já foi importado antes.',
  },
  {
    message: 'products-unavailable',
    pt: 'Produto esgotado.',
  },
  {
    message: 'coupon-already-exists',
    pt: 'Este Cupom já existe.',
  },
  {
    message: 'coupon-not-found',
    pt: 'Cupom não encontrado.',
  },
  {
    message: 'coupon-invalid',
    pt: 'Cupom inválido.',
  },
  {
    message: 'coupon-invalid-for-this-seller',
    pt: 'Cupom inválido para este restaurante.',
  },
  {
    message: 'coupon-already-used',
    pt: 'Cupom esgotado.',
  },
  {
    message: 'coupon-expired',
    pt: 'Cupom expirado.',
  },
  {
    message: 'min-coupon-price-exceeded',
    pt: 'Preço mínimo do Cupom excedido.',
  },
  {
    message: 'max-coupon-price-exceeded',
    pt: 'Preço máximo do Cupom excedido.',
  },
  {
    message: 'No orders were found.',
    pt: 'Nenhum pedido encontrado.',
  },
  {
    message: 'customer-not-found',
    pt: 'Cliente não encontrado.',
  },
  {
    message: 'customer-address-not-found',
    pt: 'Endereço não encontrado.',
  },
  {
    message: 'customer-payment-not-found',
    pt: 'Forma de pagamento não encontrada.',
  },
  {
    message: 'customer-document-already-registered',
    pt: 'Este documento já esta sendo usado.',
  },
  {
    message: 'customer-email-already-registered',
    pt: 'Este email já esta sendo usado.',
  },
  {
    message: 'missing-customer-feature-group-to-assign',
    pt: 'Por favor, crie as permissões antes de criar um usuário.',
  },
  {
    message: 'user-not-found',
    pt: 'Usuário não encontrado.',
  },
  {
    message: 'incorrect-login-password',
    pt: 'Email e/ou senha incorretos.',
  },
  {
    message: 'e-mail-already-in-use',
    pt: 'Este Email já esta sendo usado.',
  },
  {
    message: 'token-expired',
    pt: 'Sessão expirada.',
  },
  {
    message: 'token-not-found',
    pt: 'Sessão expirada.',
  },
  {
    message: 'refresh-token-invalid',
    pt: 'Sessão expirada.',
  },
  {
    message: 'authentication-error',
    pt: 'Sessão expirada.',
  },
  {
    message: 'token-is-missing',
    pt: 'Sessão expirada.',
  },
  {
    message: 'insufficient-privilege',
    pt: 'Permissões insuficientes.',
  },
  {
    message: 'disabled-seller',
    pt: 'Restaurante inativado.',
  },
  {
    message: 'order-not-found',
    pt: 'Pedido não encontrado.',
  },
  {
    message: 'order-request-no-status-changes',
    pt: 'Sem alterações no status do Pedido.',
  },
  {
    message: 'missing-order-payment-charge',
    pt: 'Forma de pagamento não encontrada.',
  },
  {
    message: 'employees-not-found',
    pt: 'Usuário não encontrado.',
  },
  {
    message: 'pay-provider-error',
    pt: 'Falha no pagamento, por favor, tente novamente mais tarde.',
  },
  {
    message: 'feature-not-found',
    pt: 'Permissão não encontrada.',
  },
  {
    message: 'feature-failed-to-update',
    pt: 'Falha ao atualizar esta Permissão.',
  },
  {
    message: 'internal-server-error',
    pt: 'Por favor, tente novamente mais tarde.',
  },
];

export const translateError = (message: string): string => {
  const findMessage = appErrorType.find((item) =>
    message.includes(item.message),
  );

  if (findMessage) {
    return findMessage.pt;
  } else {
    return 'Por favor, tente novamente mais tarde.';
  }
};
