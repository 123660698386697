import { io } from 'socket.io-client';

export const socket = io(
  String(
    process.env.REACT_APP_SOCKET_API
      ? process.env.REACT_APP_SERVER_API
      : process.env.REACT_APP_SOCKET_API,
  ),
  {
    reconnectionDelayMax: 5000,
    transports: ['websocket'],
  },
);

export const registerSocketByUser = (userId: string): void => {
  socket.emit('employee_connected', userId);
};

export const removeSocketByUser = (userId: string): void => {
  socket.emit('employee_disconnected', userId);
};
