import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { retrieveUserToken } from 'utils/storage/localStorage';

const API_BASE_URL = process.env.REACT_APP_SERVER_API;

const request = async (params: AxiosRequestConfig) => {
  const jwtToken = retrieveUserToken();

  if (jwtToken) {
    params.headers = {
      ...params.headers,
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    };
  }

  return axios({ ...params });
};

export const get = (
  config: AxiosRequestConfig,
  baseURL?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any>> =>
  request({ baseURL: baseURL || API_BASE_URL, ...config, method: 'GET' });

export const post = (
  config: AxiosRequestConfig,
  baseURL?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any>> =>
  request({ baseURL: baseURL || API_BASE_URL, ...config, method: 'POST' });

export const put = (
  config: AxiosRequestConfig,
  baseURL?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any>> =>
  request({ baseURL: baseURL || API_BASE_URL, ...config, method: 'PUT' });

export const patch = (
  config: AxiosRequestConfig,
  baseURL?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any>> =>
  request({ baseURL: baseURL || API_BASE_URL, ...config, method: 'PATCH' });

export const del = (
  config: AxiosRequestConfig,
  baseURL?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any>> =>
  request({ baseURL: baseURL || API_BASE_URL, ...config, method: 'DELETE' });

export default { del, get, patch, post, put };
