import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  ForgotPassword,
  Login,
  ResetPasswordPayload,
  SignUpPayload,
} from 'models/auth';
import { User } from 'models/user';
import { GenericError } from 'models/request';

export const login = createAsyncAction(
  '@@admin/LOGIN_REQUEST',
  '@@admin/LOGIN_REQUEST_SUCCESS',
  '@@admin/LOGIN_REQUEST_FAILURE',
)<Login, User, GenericError>();

export const registerUser = createAsyncAction(
  '@@admin/REGISTER_USER_REQUEST',
  '@@admin/REGISTER_USER_REQUEST_SUCCESS',
  '@@admin/REGISTER_USER_REQUEST_FAILURE',
)<SignUpPayload, undefined, GenericError>();

export const resetCustomerPassword = createAsyncAction(
  '@@admin/RESET_CUSTOMER_PASSWORD_REQUEST',
  '@@admin/RESET_CUSTOMER_PASSWORD_SUCCESS',
  '@@admin/RESET_CUSTOMER_PASSWORD_FAILURE',
)<ResetPasswordPayload, undefined, GenericError>();

export const forgotUserPassword = createAsyncAction(
  '@@admin/FORGOT_CUSTOMER_PASSWORD_REQUEST',
  '@@admin/FORGOT_CUSTOMER_PASSWORD_SUCCESS',
  '@@admin/FORGOT_CUSTOMER_PASSWORD_FAILURE',
)<ForgotPassword, undefined, GenericError>();

export const resetUserPassword = createAsyncAction(
  '@@admin/RESET_USER_PASSWORD_REQUEST',
  '@@admin/RESET_USER_PASSWORD_SUCCESS',
  '@@admin/RESET_USER_PASSWORD_FAILURE',
)<ResetPasswordPayload, undefined, GenericError>();

export const resetAuthStatus = createAction('@@admin/RESET_LOGIN_STATUS')();

export const requestLogOut = createAction('@@admin/REQUEST_LOG_OUT')();
