import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { socket } from 'websocket';

import alertSound from 'assets/sounds/bell.mp3';

export type NotificationType = {
  employee: string;
  orderId: string;
  orderNumber: number;
  orderStatus: string;
  sellerId: string;
  updatedAt: Date;
};

type PropsNotificationsContext = {
  notifications: NotificationType[];
  setNotifications: React.Dispatch<React.SetStateAction<NotificationType[]>>;
};

const NotificationsContext = createContext({} as PropsNotificationsContext);

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [audio] = useState<HTMLAudioElement>(new Audio(alertSound));
  const [playing, setPlaying] = useState(false);

  const startWarningSound = useCallback(async () => {
    if (playing) return;

    setPlaying(true);
    audio.load();
    await audio.play();
  }, []);

  const stopWarningSound = (): void => {
    if (!playing) return;

    setPlaying(false);
    audio.pause();
  };

  useEffect(() => {
    socket.on('order_notification', (data: NotificationType) => {
      setNotifications((prevState) => {
        const includesSameNotification = prevState.some(
          (item) =>
            data.orderId === item.orderId && data.updatedAt === item.updatedAt,
        );

        if (prevState && !includesSameNotification) {
          startWarningSound();

          return [data, ...prevState];
        } else return prevState ? prevState : [];
      });
    });
  }, []);

  useEffect(() => {
    if (!notifications.length) {
      stopWarningSound();
    }
  }, [notifications]);

  return (
    <NotificationsContext.Provider value={{ notifications, setNotifications }}>
      {children}
    </NotificationsContext.Provider>
  );
};
