import { usePathID } from 'hooks/location';
import { RequestStatus } from 'models/request';
import { Seller } from 'models/seller';
import { useSelector } from 'store/selector';
import { useHistory } from 'react-router-dom';
import { useOperatorSeller } from 'hooks/selectors/auth';

export const useSeller = () => useSelector((state) => state.seller);

export const useSellerList = (): Seller[] => {
  const list = useSeller().list.data;
  const operatorSeller = useOperatorSeller();

  if (operatorSeller && !list.some((item) => item.id === operatorSeller.id)) {
    list.push({ ...operatorSeller });
  }

  return list;
};

export const useSellersOperationData = () => useSeller().operation;

export const useSellerOperationStatus = (): RequestStatus =>
  useSeller().operation.status;

export const useRequestedSellerData = () => useSeller().requestedSeller;

export const useSellerByID = (id: string): Seller | undefined => {
  const list = useSellerList();

  return list.find((seller) => seller.id === id);
};

export const useSellerFromPath = (): Seller | undefined => {
  const id = usePathID();
  const list = useSellerList();

  return list.find((seller) => seller.id === id);
};

type SellerSelection = {
  boundBySeller: boolean;
  seller: Seller | undefined;
};

export const useSelectedSeller = (): SellerSelection => {
  const pathSeller = useSellerFromPath();
  const operatorSeller = useOperatorSeller();
  const seller = operatorSeller || pathSeller;

  return { boundBySeller: !!operatorSeller, seller };
};

export const useSellerView = (): boolean => {
  const history = useHistory();
  const isSellerBound = !!useOperatorSeller();

  return isSellerBound || history.location.pathname.startsWith('/seller');
};
