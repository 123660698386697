import { get, patch, post } from 'api';
import { Customer, CustomerPayload } from 'models/customer';
import { PaginationPayload } from 'models/request';
import { Unregistered } from 'models/generic';

export const getCustomers = (data: PaginationPayload) =>
  get({ params: data, url: '/customers' });

export const getNewCustomersByDate = () => get({ url: '/customers/list-new' });

export const getTotalCustomers = (daysLimit: number) => get({ url: '/customers/metrics-total', params: { daysLimit } });

export const getCustomer = (data: string) => get({ url: `/customer/${data}` });

export const postCustomer = (data: Unregistered<Customer>) =>
  post({ data, url: '/customers' });

export const patchCustomer = (data: CustomerPayload) =>
  patch({ data, url: `/customers/${data.id}` });
