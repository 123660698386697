import { del, get, patch, post } from 'api';
import { Patch, Unregistered } from 'models/generic';
import { PaginationPayload } from 'models/request';
import { Seller, SellerPayload } from 'models/seller';
import {
  SellerDeliveringPayload,
  SellerImagePayload,
  SellerWorkingHoursPayload,
} from './types';

export const getSellers = (data: PaginationPayload) =>
  post({ data, url: '/sellers' });

export const getSeller = (id: string) => get({ url: `/seller/${id}` });

export const postSeller = (data: Unregistered<Seller>) =>
  post({ data, url: '/seller' });

export const patchSeller = (data: Patch<SellerPayload>) =>
  patch({ data, url: `/seller/${data.id}` });

export const patchSellerWorkingHours = (data: SellerWorkingHoursPayload) =>
  patch({ data, url: '/seller/working-hours' });

export const patchSellerIsDeliverable = (data: SellerDeliveringPayload) =>
  patch({
    data: { delivering: data.delivering },
    url: `/seller/${data.sellerId}/delivering`,
  });

export const delSeller = (id: string) => del({ url: `/seller/${id}` });

export const uploadAvatar = (data: SellerImagePayload) => {
  const formData = new FormData();

  if (data.image) {
    formData.append('avatar', data.image);
  }

  return patch({
    data: formData,
    url: `/images/sellers/${data.sellerID}/avatar`,
  });
};

export const uploadCover = (data: SellerImagePayload) => {
  const formData = new FormData();

  if (data.image) {
    formData.append('cover', data.image);
  }

  return patch({
    data: formData,
    url: `/images/sellers/${data.sellerID}/cover`,
  });
};

export const getAddressGeolocation = (address: string) =>
  get({ url: `/locations/forward?address=${address}` });
