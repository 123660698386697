import { createReducer } from 'typesafe-actions';
import { ToastConfig, ToastSeverity } from 'modules/ui/toast/types';
import {
  dismissToast,
  requestErrorToast,
  requestSuccessToast,
  requestToast,
} from 'modules/ui/toast/actions';

type State = {
  config: ToastConfig | null;
  requested: boolean;
};

const initialState: State = {
  config: null,
  requested: false,
};

export const toast = createReducer(initialState)
  .handleAction(requestToast, (state, action) => {
    const { payload } = action;
    const newState = { ...state, requested: true };

    if (typeof payload === 'string') {
      newState.config = {
        message: payload,
        severity: ToastSeverity.INFO,
      };
    } else {
      newState.config = {
        ...payload,
      };
    }

    return newState;
  })
  .handleAction(requestSuccessToast, (state, action) => ({
    config: { message: action.payload, severity: ToastSeverity.SUCCESS },
    requested: true,
  }))
  .handleAction(requestErrorToast, (state, action) => ({
    config: { message: action.payload, severity: ToastSeverity.ERROR },
    requested: true,
  }))
  .handleAction(dismissToast, () => ({ ...initialState }));
