import { createReducer } from 'typesafe-actions';
import { RequestStatus } from 'models/request';
import { User } from 'models/user';
import {
  forgotUserPassword,
  login,
  registerUser,
  requestLogOut,
  resetAuthStatus,
  resetCustomerPassword,
  resetUserPassword,
} from 'modules/auth/actions';
import { IntegratedState } from 'models/state';

type State = IntegratedState<User | null>;

const initialState: State = {
  data: null,
  error: undefined,
  status: RequestStatus.IDLE,
};

export const auth = createReducer(initialState)
  .handleAction(
    [
      login.request,
      registerUser.request,
      resetCustomerPassword.request,
      forgotUserPassword.request,
      resetUserPassword.request,
    ],
    () => ({
      ...initialState,
      status: RequestStatus.FETCHING,
    }),
  )
  .handleAction(login.success, (state, action) => ({
    data: action.payload,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(login.failure, (state, action) => ({
    data: null,
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(resetAuthStatus, (state) => ({
    ...state,
    error: undefined,
    status: RequestStatus.IDLE,
  }))
  .handleAction(registerUser.success, (state) => ({
    ...state,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(registerUser.failure, (state, action) => ({
    data: null,
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(resetCustomerPassword.success, (state) => ({
    ...state,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(resetCustomerPassword.failure, (state, action) => ({
    data: null,
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(forgotUserPassword.success, (state) => ({
    ...state,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(forgotUserPassword.failure, (state, action) => ({
    data: null,
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(resetUserPassword.success, (state) => ({
    ...state,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(resetUserPassword.failure, (state, action) => ({
    data: null,
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(requestLogOut, () => ({ ...initialState }));
