import { createAction, createAsyncAction } from 'typesafe-actions';
import { GenericError } from 'models/request';
import { SellerCategory } from 'models/platform/sellerCategory';
import { Patch, Unregistered } from 'models/generic';

export const listSellerCategories = createAsyncAction(
  '@@admin/LIST_SELLER_CATEGORIES',
  '@@admin/LIST_SELLER_CATEGORIES_SUCCESS',
  '@@admin/LIST_SELLER_CATEGORIES_FAILURE',
)<string, SellerCategory[], GenericError>();

export const listSellerCategoryChildren = createAsyncAction(
  '@@admin/LIST_SELLER_CATEGORY_CHILDREN',
  '@@admin/LIST_SELLER_CATEGORY_CHILDREN_SUCCESS',
  '@@admin/LIST_SELLER_CATEGORY_CHILDREN_FAILURE',
)<string, SellerCategory, GenericError>();

export const createSellerCategory = createAsyncAction(
  '@@admin/CREATE_SELLER_CATEGORY_REQUEST',
  '@@admin/CREATE_SELLER_CATEGORY_REQUEST_SUCCESS',
  '@@admin/CREATE_SELLER_CATEGORY_REQUEST_FAILURE',
)<Unregistered<SellerCategory>, SellerCategory, GenericError>();

export const updateSellerCategory = createAsyncAction(
  '@@admin/UPDATE_SELLER_CATEGORY_REQUEST',
  '@@admin/UPDATE_SELLER_CATEGORY_REQUEST_SUCCESS',
  '@@admin/UPDATE_SELLER_CATEGORY_REQUEST_FAILURE',
)<Patch<SellerCategory>, SellerCategory, GenericError>();

export const deleteSellerCategory = createAsyncAction(
  '@@admin/DELETE_SELLER_CATEGORY_REQUEST',
  '@@admin/DELETE_SELLER_CATEGORY_REQUEST_SUCCESS',
  '@@admin/DELETE_SELLER_CATEGORY_REQUEST_FAILURE',
)<string, string, GenericError>();

export const resetSellerCategoryOperation = createAction(
  '@@admin/RESET_SELLER_CATEGORY_OPERATION_STATUS',
)();
