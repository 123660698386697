import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { IntegratedListState, IntegratedState } from 'models/state';
import { Feature, FeatureGroup } from 'models/feature';
import { RequestStatus } from 'models/request';
import {
  createFeatureGroup,
  deleteFeatureGroup,
  listFeatureGroups,
  listFeatures,
  resetFeatureGroupOperationData,
  updateFeatureGroup,
} from 'modules/feature/actions';

type FeatureState = IntegratedListState<Feature[]>;

const initialFeatureState: FeatureState = {
  count: 0,
  data: [],
  error: undefined,
  status: RequestStatus.IDLE,
};

const featureReducer = createReducer(initialFeatureState)
  .handleAction(listFeatures.request, () => ({
    ...initialFeatureState,
    status: RequestStatus.FETCHING,
  }))
  .handleAction(listFeatures.success, (state, action) => ({
    count: action.payload.count,
    data: action.payload.list,
    error: undefined,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(listFeatures.failure, (state, action) => ({
    ...initialFeatureState,
    error: action.payload,
    status: RequestStatus.FAILURE,
  }));

type FeatureGroupState = IntegratedListState<FeatureGroup[]>;

const initialFeatureGroupState: FeatureGroupState = {
  count: 0,
  data: [],
  error: undefined,
  status: RequestStatus.IDLE,
};

const featureGroupReducer = createReducer(initialFeatureGroupState)
  .handleAction(listFeatureGroups.request, () => ({
    ...initialFeatureGroupState,
    status: RequestStatus.FETCHING,
  }))
  .handleAction(listFeatureGroups.success, (state, action) => ({
    count: action.payload.count,
    data: action.payload.list,
    error: undefined,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(listFeatureGroups.failure, (state, action) => ({
    ...initialFeatureGroupState,
    error: action.payload,
    status: RequestStatus.FAILURE,
  }))
  .handleAction(createFeatureGroup.success, (state, action) => ({
    ...state,
    count: state.count + 1,
    data: [...state.data, action.payload],
  }))
  .handleAction(updateFeatureGroup.success, (state, action) => ({
    ...state,
    data: state.data.map((group) =>
      group.id === action.payload.id ? { ...group, ...action.payload } : group,
    ),
  }))
  .handleAction(deleteFeatureGroup.success, (state, action) => ({
    ...state,
    count: state.count - 1,
    data: state.data.filter((group) => group.id !== action.payload),
  }));

const initialOperationState: IntegratedState<FeatureGroup | null> = {
  data: null,
  error: undefined,
  status: RequestStatus.IDLE,
};

const operationReducer = createReducer(initialOperationState)
  .handleAction(
    [
      createFeatureGroup.request,
      updateFeatureGroup.request,
      deleteFeatureGroup.request,
    ],
    (state) => ({
      ...state,
      ...initialOperationState,
      status: RequestStatus.FETCHING,
    }),
  )
  .handleAction(
    [
      createFeatureGroup.success,
      updateFeatureGroup.success,
    ],
    (state, action) => ({
      ...state,
      data: action.payload,
      status: RequestStatus.SUCCESS,
    }),
  )
  .handleAction(deleteFeatureGroup.success, (state) => ({
    ...state,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(
    [
      createFeatureGroup.failure,
      updateFeatureGroup.failure,
      deleteFeatureGroup.failure,
    ],
    (state, action) => ({
      ...initialOperationState,
      error: {
        ...action.payload,
      },
      status: RequestStatus.FAILURE,
    }),
  )
  .handleAction(resetFeatureGroupOperationData, () => ({
    ...initialOperationState,
  }));

export const feature = combineReducers({
  features: featureReducer,
  groups: featureGroupReducer,
  operation: operationReducer,
});
