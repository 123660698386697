import { applyMiddleware, createStore, Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer, { history as reducerHistory } from './reducer';
import createMiddlewares, { sagaMiddleware } from './middlewares';
import rootSaga from './saga';
import stateReconciler from './stateReconciler';

const persistedConfig = {
  blacklist: ['ui'],
  debug: process.env.NODE_ENV !== 'production',
  key: 'root',
  stateReconciler,
  storage,
};

const persistedReducer = persistReducer(persistedConfig, rootReducer);

export const history = reducerHistory;

export const store: Store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...createMiddlewares(history))),
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
