import { createReducer } from 'typesafe-actions';
import { RequestStatus } from 'models/request';
import {
  deleteUser,
  inviteUser,
  listUsers,
  resetUserOperation,
  updateUser,
} from './actions';
import { User } from 'models/user';
import { IntegratedListState, IntegratedState } from 'models/state';
import { combineReducers } from 'redux';
import { updateFeatureGroup } from 'modules/feature/actions';

const initialListState: IntegratedListState<User[]> = {
  count: 0,
  data: [],
  error: undefined,
  status: RequestStatus.IDLE,
};

const listReducer = createReducer(initialListState)
  .handleAction(listUsers.request, (state) => ({
    ...state,
    status: RequestStatus.FETCHING,
  }))
  .handleAction(listUsers.success, (state, action) => ({
    ...state,
    count: action.payload.count,
    data: [...action.payload.list],
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(listUsers.failure, (state, action) => ({
    ...initialListState,
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(inviteUser.success, (state, action) => ({
    ...state,
    count: state.count + 1,
    data: [...state.data, action.payload],
  }))
  .handleAction(updateUser.success, (state, action) => ({
    ...state,
    data: state.data.map((user) =>
      user.id === action.payload.id ? { ...user, ...action.payload } : user,
    ),
  }))
  .handleAction(deleteUser.success, (state, action) => ({
    ...state,
    count: state.count - 1,
    data: state.data.filter((user) => user.id !== action.payload),
  }))
  .handleAction(updateFeatureGroup.success, (state, action) => ({
    ...state,
    data: state.data.map((user) => ({
      ...user,
      featureGroups: user.featureGroups.map((group) =>
        group.id === action.payload.id
          ? { ...group, ...action.payload }
          : group,
      ),
    })),
  }));

const initialOperationState: IntegratedState<null> = {
  data: null,
  error: undefined,
  status: RequestStatus.IDLE,
};

const operationReducer = createReducer(initialOperationState)
  .handleAction(
    [inviteUser.request, updateUser.request, deleteUser.request],
    () => ({
      ...initialOperationState,
      status: RequestStatus.FETCHING,
    }),
  )
  .handleAction(
    [inviteUser.success, updateUser.success, deleteUser.success],
    (state) => ({
      ...state,
      status: RequestStatus.SUCCESS,
    }),
  )
  .handleAction(
    [inviteUser.failure, updateUser.failure, deleteUser.failure],
    (state, action) => ({
      ...initialOperationState,
      error: {
        ...action.payload,
      },
      status: RequestStatus.FAILURE,
    }),
  )
  .handleAction(resetUserOperation, () => ({
    ...initialOperationState,
  }));

export const user = combineReducers({
  list: listReducer,
  operation: operationReducer,
});
