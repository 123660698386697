import { RequestStatus } from 'models/request';
import { Seller } from 'models/seller';
import { IntegratedListState, IntegratedState } from 'models/state';
import { createReducer } from 'typesafe-actions';

import {
  createSeller,
  deleteSeller,
  fetchSeller,
  listSellers,
  resetFetchedSellerData,
  resetSellerOperation,
  updateSeller,
  updateSellerIsDeliverable,
  updateSellerWorkingHours,
} from './actions';
import { combineReducers } from 'redux';
import { productCategory } from 'modules/seller/productCategory/reducer';

const initialListState: IntegratedListState<Seller[]> = {
  count: 0,
  data: [],
  error: undefined,
  status: RequestStatus.IDLE,
};

const listReducer = createReducer(initialListState)
  .handleAction(listSellers.request, () => ({
    ...initialListState,
    status: RequestStatus.FETCHING,
  }))
  .handleAction(listSellers.success, (state, action) => ({
    ...state,
    count: action.payload.count,
    data: [...action.payload.list],
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(listSellers.failure, (state, action) => ({
    ...initialListState,
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(createSeller.success, (state, action) => ({
    ...state,
    count: state.count + 1,
    data: [...state.data, action.payload],
  }))
  .handleAction(updateSeller.success, (state, action) => ({
    ...state,
    data: state.data.map((seller) =>
      seller.id === action.payload.id
        ? { ...seller, ...action.payload }
        : seller,
    ),
  }))
  .handleAction(updateSellerWorkingHours.success, (state, action) => ({
    ...state,
    data: state.data.map((seller) =>
      seller.id === action.payload.id
        ? { ...seller, ...action.payload }
        : seller,
    ),
  }))
  .handleAction(updateSellerIsDeliverable.success, (state, action) => ({
    ...state,
    data: state.data.map((seller) =>
      seller.id === action.payload.id
        ? { ...seller, ...action.payload }
        : seller,
    ),
  }))
  .handleAction(deleteSeller.success, (state, action) => ({
    ...state,
    count: state.count - 1,
    data: state.data.filter((seller) => seller.id !== action.payload),
  }));

const initialOperationState: IntegratedState<Seller | null> = {
  data: null,
  error: undefined,
  status: RequestStatus.IDLE,
};

const operationReducer = createReducer(initialOperationState)
  .handleAction(
    [
      createSeller.request,
      updateSeller.request,
      updateSellerWorkingHours.request,
      updateSellerIsDeliverable.request,
      deleteSeller.request,
    ],
    (state) => ({
      ...state,
      status: RequestStatus.FETCHING,
    }),
  )
  .handleAction(
    [
      createSeller.success,
      updateSeller.success,
      updateSellerWorkingHours.success,
      updateSellerIsDeliverable.success,
    ],
    (state, action) => ({
      ...state,
      data: action.payload,
      status: RequestStatus.SUCCESS,
    }),
  )
  .handleAction(deleteSeller.success, (state) => ({
    ...state,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(
    [
      createSeller.failure,
      updateSeller.failure,
      updateSellerWorkingHours.failure,
      updateSellerIsDeliverable.failure,
      deleteSeller.failure,
    ],
    (state, action) => ({
      ...initialOperationState,
      error: {
        ...action.payload,
      },
      status: RequestStatus.FAILURE,
    }),
  )
  .handleAction(resetSellerOperation, () => ({
    ...initialOperationState,
  }));

const initialRequestSellerState: IntegratedState<Seller | null> = {
  data: null,
  error: undefined,
  status: RequestStatus.IDLE,
};

const requestedSellerReducer = createReducer(initialRequestSellerState)
  .handleAction(fetchSeller.request, () => ({
    ...initialRequestSellerState,
    status: RequestStatus.FETCHING,
  }))
  .handleAction(fetchSeller.success, (state, action) => ({
    ...state,
    data: action.payload,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(fetchSeller.failure, (state, action) => ({
    ...initialRequestSellerState,
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(resetFetchedSellerData, () => ({
    ...initialRequestSellerState,
  }));

export const seller = combineReducers({
  categories: productCategory,
  list: listReducer,
  operation: operationReducer,
  requestedSeller: requestedSellerReducer,
});
