import { useSelector } from 'store/selector';
import { Seller } from 'models/seller';
import { RequestStatus } from 'models/request';
import { User } from 'models/user';
import { useSellerFromPath } from 'hooks/selectors/seller';
import { OrderStatus } from 'models/order';

export const useAuthData = () => useSelector((state) => state.auth);

export const useLoggedUser = (): User | null => useAuthData().data;

export const useLoggedIn = (): boolean => !!useLoggedUser();

export const useAuthStatus = (): RequestStatus => useAuthData().status;

export const useOperatorSeller = (): Seller | null =>
  useLoggedUser()?.seller || null;

export const useOperatorSellerValidation = (): boolean => {
  const user = useLoggedUser();
  const pathSeller = useSellerFromPath();
  const seller = user?.seller;
  const sellerBoundOperator = !!seller;

  return !sellerBoundOperator || pathSeller?.id === seller?.id;
};

export const usePermissionValidation = (...args: string[]): boolean => {
  const user = useLoggedUser();

  const allowedFeature =
    !!user && user.features.some((feature) => args.includes(feature.key));

  return allowedFeature;
};

export const useUserOrderStatusPermissions = (): {
  orderStatusNotAllowedByUser: OrderStatus[];
  orderFullAccess: boolean;
} => {
  const user = useLoggedUser();
  const orderStatusUpperCase = [
    'ORDER_AWAITING_WITHDRAW',
    'ORDER_CANCELED_BY_CUSTOMER',
    'ORDER_CANCELED_BY_SELLER',
    'ORDER_DELIVERED',
    'ORDER_IN_TRANSPORT',
    'ORDER_CREATED',
    'ORDER_PAYMENT_CANCELED',
    'ORDER_PAYMENT_CONFIRMED',
    'ORDER_PAYMENT_REJECTED',
    'ORDER_PREPARING',
    'ORDER_SCHEDULED',
  ];

  if (!user) {
    return {
      orderStatusNotAllowedByUser: [
        ...(orderStatusUpperCase
          .map((_, key) => key)
          .filter(Number) as number[]),
      ],
      orderFullAccess: false,
    };
  }

  const userPermissions = user.features.map((feature) => feature.key);

  // user.featureGroups?.map((featureGroup) =>
  //   featureGroup.features.map((feature) => {
  //     return feature.key;
  //   }),
  // );

  const orderStatusNotAllowedByUser = orderStatusUpperCase
    .map((status, key) => {
      if (!userPermissions.includes(status)) {
        return key;
      }
    })
    .filter(Number) as number[];

  return {
    orderStatusNotAllowedByUser: orderStatusNotAllowedByUser,
    orderFullAccess: userPermissions.includes('ORDER_FULL_ACCESS'),
  };
};
