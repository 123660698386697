import { createReducer } from 'typesafe-actions';
import { RequestStatus } from 'models/request';
import { IntegratedState } from 'models/state';
import { ProductCategory } from 'models/seller/productCategory';
import {
  createProductCategory,
  deleteProductCategory,
  listProductCategories,
  listProductCategoryChildren,
  resetProductCategoryOperation,
  updateProductCategory,
} from './actions';
import { TreeView } from 'models/generic';
import updateDataList from 'utils/data/updateDataList';
import filterDataList from 'utils/data/filterDataList';
import insertIntoDataList from 'utils/data/insetIntoDataList';

type State = IntegratedState<TreeView<ProductCategory>[]> & {
  childrenFetchStatus: RequestStatus;
  operationStatus: RequestStatus;
};

const initialState: State = {
  childrenFetchStatus: RequestStatus.IDLE,
  data: [],
  error: undefined,
  operationStatus: RequestStatus.IDLE,
  status: RequestStatus.IDLE,
};

export const productCategory = createReducer(initialState)
  .handleAction(listProductCategories.request, (state) => ({
    ...state,
    error: undefined,
    status: RequestStatus.FETCHING,
  }))
  .handleAction(listProductCategories.success, (state, action) => ({
    ...state,
    data: [...action.payload.map((item) => ({ ...item, updated: false }))],
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(listProductCategories.failure, (state, action) => ({
    ...state,
    data: [],
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(listProductCategoryChildren.request, (state) => ({
    ...state,
    childrenFetchStatus: RequestStatus.FETCHING,
  }))
  .handleAction(listProductCategoryChildren.success, (state, action) => ({
    ...state,
    childrenFetchStatus: RequestStatus.SUCCESS,
    data: [...updateDataList(state.data, action.payload)],
  }))
  .handleAction(listProductCategoryChildren.failure, (state, action) => ({
    ...state,
    childrenFetchStatus: RequestStatus.FAILURE,
    error: {
      ...action.payload,
    },
  }))
  .handleAction(
    [
      createProductCategory.request,
      updateProductCategory.request,
      deleteProductCategory.request,
    ],
    (state) => ({
      ...state,
      operationStatus: RequestStatus.FETCHING,
    }),
  )
  .handleAction(createProductCategory.success, (state, action) => ({
    ...state,
    data: [...insertIntoDataList(state.data, action.payload)],
    operationStatus: RequestStatus.SUCCESS,
  }))
  .handleAction(updateProductCategory.success, (state, action) => ({
    ...state,
    data: [...updateDataList(state.data, action.payload)],
    operationStatus: RequestStatus.SUCCESS,
  }))
  .handleAction(deleteProductCategory.success, (state, action) => ({
    ...state,
    data: [...filterDataList(state.data, action.payload)],
    operationStatus: RequestStatus.SUCCESS,
  }))
  .handleAction(
    [
      createProductCategory.failure,
      updateProductCategory.failure,
      deleteProductCategory.failure,
    ],
    (state, action) => ({
      ...state,
      error: {
        ...action.payload,
      },
      operationStatus: RequestStatus.FAILURE,
    }),
  )
  .handleAction(resetProductCategoryOperation, (state) => ({
    ...state,
    operationStatus: RequestStatus.IDLE,
  }));
