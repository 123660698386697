import { Customer, CustomerPayload } from 'models/customer';
import { GenericError, PaginationPayload } from 'models/request';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { Paginated, Unregistered } from 'models/generic';

export const listCustomers = createAsyncAction(
  '@@admin/CUSTOMER_LIST_REQUEST',
  '@@admin/CUSTOMER_LIST_REQUEST_SUCCESS',
  '@@admin/CUSTOMER_LIST_REQUEST_FAILURE',
)<PaginationPayload, Paginated<Customer[]>, GenericError>();

export const fetchCustomer = createAsyncAction(
  '@@admin/FETCH_CUSTOMER_REQUEST',
  '@@admin/FETCH_CUSTOMER_REQUEST_SUCCESS',
  '@@admin/FETCH_CUSTOMER_REQUEST_FAILURE',
)<string, Customer, GenericError>();

export const createCustomer = createAsyncAction(
  '@@admin/CREATE_CUSTOMER_REQUEST',
  '@@admin/CREATE_CUSTOMER_REQUEST_SUCCESS',
  '@@admin/CREATE_CUSTOMER_REQUEST_FAILURE',
)<Unregistered<Customer>, Customer, GenericError>();

export const updateCustomer = createAsyncAction(
  '@@admin/UPDATE_CUSTOMER_REQUEST',
  '@@admin/UPDATE_CUSTOMER_REQUEST_SUCCESS',
  '@@admin/UPDATE_CUSTOMER_REQUEST_FAILURE',
)<CustomerPayload, Customer, GenericError>();

export const resetCustomerOperationData = createAction(
  '@@admin/RESET_CUSTOMER_OPERATION_DATA',
)();

export const resetFetchedCustomerData = createAction(
  '@@admin/RESET_FETCHED_CUSTOMER_DATA',
)();
