export enum ToastSeverity {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export type ToastConfig = {
  message: string;
  severity: ToastSeverity;
};
