import { APIResponse } from 'models/request';
import { call, put, takeEvery } from 'redux-saga/effects';
import handleError from 'api/handleError';
import { ProductCategory } from 'models/seller/productCategory';
import {
  createProductCategory,
  deleteProductCategory,
  listProductCategories,
  listProductCategoryChildren,
  updateProductCategory,
} from './actions';
import {
  delProductCategory,
  getProductCategoriesBySeller,
  getProductCategory,
  patchProductCategory,
  postProductCategory,
} from './services';

function* listSellerCategoriesSaga({
  payload,
}: ReturnType<typeof listProductCategories.request>): Generator {
  try {
    const { data }: APIResponse<ProductCategory[]> = (yield call(
      getProductCategoriesBySeller,
      payload,
    )) as APIResponse<ProductCategory[]>;

    yield put(listProductCategories.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(listProductCategories.failure(data));
  }
}

function* listProductCategoryChildrenSaga({
  payload,
}: ReturnType<typeof listProductCategoryChildren.request>): Generator {
  try {
    const { data }: APIResponse<ProductCategory> = (yield call(
      getProductCategory,
      payload,
    )) as APIResponse<ProductCategory>;

    yield put(listProductCategoryChildren.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(listProductCategoryChildren.failure(data));
  }
}

function* createProductCategorySaga({
  payload,
}: ReturnType<typeof createProductCategory.request>): Generator {
  try {
    const { data }: APIResponse<ProductCategory> = (yield call(
      postProductCategory,
      payload,
    )) as APIResponse<ProductCategory>;

    yield put(createProductCategory.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(createProductCategory.failure(data));
  }
}

function* updateProductCategorySaga({
  payload,
}: ReturnType<typeof updateProductCategory.request>): Generator {
  try {
    const { data }: APIResponse<ProductCategory> = (yield call(
      patchProductCategory,
      payload,
    )) as APIResponse<ProductCategory>;

    yield put(updateProductCategory.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(updateProductCategory.failure(data));
  }
}

function* deleteProductCategorySaga({
  payload,
}: ReturnType<typeof deleteProductCategory.request>): Generator {
  try {
    yield call(delProductCategory, payload);

    yield put(deleteProductCategory.success(payload));
  } catch (err) {
    const { data } = handleError(err);

    yield put(deleteProductCategory.failure(data));
  }
}

export default [
  takeEvery(listProductCategories.request, listSellerCategoriesSaga),
  takeEvery(
    listProductCategoryChildren.request,
    listProductCategoryChildrenSaga,
  ),
  takeEvery(createProductCategory.request, createProductCategorySaga),
  takeEvery(updateProductCategory.request, updateProductCategorySaga),
  takeEvery(deleteProductCategory.request, deleteProductCategorySaga),
];
