import { del, patch, post } from 'api';
import { PaginationPayload } from 'models/request';
import { Patch, Unregistered } from 'models/generic';
import { FeatureGroup } from 'models/feature';

export const getFeatures = (data: PaginationPayload) =>
  post({ data, url: '/features' });

export const getFeatureGroups = (data: PaginationPayload) =>
  post({ data, url: '/feature-groups' });

export const postFeatureGroup = (data: Unregistered<FeatureGroup>) =>
  post({ data, url: '/feature-group' });

export const patchFeatureGroup = (data: Patch<FeatureGroup>) =>
  patch({ data, url: '/feature-group' });

export const delFeatureGroup = (id: string) =>
  del({ url: `/feature-group/${id}` });
