import { createAction } from 'typesafe-actions';
import { Platform } from 'models/platform';

export const setSelectedPlatform = createAction(
  '@@ui/SET_SELECTED_PLATFORM',
)<Platform>();

export const clearSelectedPlatform = createAction(
  '@@ui/CLEAR_SELECTED_PLATFORM',
)();
