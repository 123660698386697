import { del, get, patch, post } from 'api';
import { PaginationPayload } from 'models/request';
import { Patch, Unregistered } from 'models/generic';
import { Platform } from 'models/platform';

export const getPlatforms = (data: PaginationPayload) =>
  post({ data, url: '/platforms' });

export const getPlatform = (id: string) => get({ url: `/platform/${id}` });

export const postPlatform = (data: Unregistered<Platform>) =>
  post({ data, url: '/platform' });

export const patchPlatform = (data: Patch<Platform>) =>
  patch({ data, url: '/platform' });

export const delPlatform = (id: string) => del({ url: `/platform/${id}` });
