import { Platform } from 'models/platform';
import { createReducer } from 'typesafe-actions';
import {
  clearSelectedPlatform,
  setSelectedPlatform,
} from 'modules/ui/platform/actions';

type State = {
  selected: Platform | null;
};

const initialState: State = {
  selected: null,
};

export const platform = createReducer(initialState)
  .handleAction(setSelectedPlatform, (state, action) => ({
    selected: action.payload,
  }))
  .handleAction(clearSelectedPlatform, () => ({ selected: null }));
