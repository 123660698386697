import { createAction } from 'typesafe-actions';
import { ToastConfig } from 'modules/ui/toast/types';

export const requestToast = createAction('@@ui/REQUEST_TOAST')<
  string | ToastConfig
>();

export const requestSuccessToast = createAction(
  '@@ui/REQUEST_SUCCESS_TOAST',
)<string>();

export const requestErrorToast = createAction(
  '@@ui/REQUEST_ERROR_TOAST',
)<string>();

export const dismissToast = createAction('@@ui/DISMISS_TOAST')();
