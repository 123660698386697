import { Customer } from 'models/customer';
import { RequestStatus } from 'models/request';
import { IntegratedListState, IntegratedState } from 'models/state';
import { createReducer } from 'typesafe-actions';

import {
  createCustomer,
  fetchCustomer,
  listCustomers,
  resetCustomerOperationData,
  updateCustomer,
} from './actions';
import { combineReducers } from 'redux';

const initialListState: IntegratedListState<Customer[]> = {
  count: 0,
  data: [],
  error: undefined,
  status: RequestStatus.IDLE,
};

const listReducer = createReducer(initialListState)
  .handleAction(listCustomers.request, () => ({
    ...initialListState,
    status: RequestStatus.FETCHING,
  }))
  .handleAction(listCustomers.success, (state, action) => ({
    ...state,
    count: action.payload.count,
    data: [...action.payload.list],
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(listCustomers.failure, (state, action) => ({
    ...initialListState,
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(
    [createCustomer.success, fetchCustomer.success],
    (state, action) => ({
      ...state,
      count: state.count + 1,
      data: [...state.data, action.payload],
    }),
  )
  .handleAction(updateCustomer.success, (state, action) => ({
    ...state,
    data: state.data.map((customer) =>
      customer.id === action.payload.id
        ? { ...customer, ...action.payload }
        : customer,
    ),
  }));
const initialOperationState: IntegratedState<Customer | null> = {
  data: null,
  error: undefined,
  status: RequestStatus.IDLE,
};

const operationReducer = createReducer(initialOperationState)
  .handleAction([createCustomer.request, updateCustomer.request], (state) => ({
    ...state,
    ...initialOperationState,
    status: RequestStatus.FETCHING,
  }))
  .handleAction(
    [createCustomer.success, updateCustomer.success],
    (state, action) => ({
      ...state,
      data: action.payload,
      status: RequestStatus.SUCCESS,
    }),
  )
  .handleAction(
    [createCustomer.failure, updateCustomer.failure],
    (state, action) => ({
      ...state,
      data: null,
      error: {
        ...action.payload,
      },
      status: RequestStatus.FAILURE,
    }),
  )
  .handleAction(resetCustomerOperationData, () => ({
    ...initialOperationState
  }));

const initialRequestCustomerState: IntegratedState<Customer | null> = {
  data: null,
  error: undefined,
  status: RequestStatus.IDLE,
};

const requestedCustomerReducer = createReducer(initialRequestCustomerState)
  .handleAction(fetchCustomer.request, (state) => ({
    ...state,
    status: RequestStatus.FETCHING,
  }))
  .handleAction(fetchCustomer.success, (state, action) => ({
    ...state,
    data: action.payload,
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(fetchCustomer.failure, (state, action) => ({
    ...state,
    data: null,
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }));

export const customer = combineReducers({
  list: listReducer,
  operation: operationReducer,
  requestedCustomer: requestedCustomerReducer,
});
