import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { history, persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core';
import themeProvider from 'theme/provider';
import 'websocket';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StylesProvider injectFirst={true}>
        <ThemeProvider theme={themeProvider}>
          <ConnectedRouter history={history}>
            <CssBaseline />
            <App />
          </ConnectedRouter>
        </ThemeProvider>
      </StylesProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
