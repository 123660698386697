import { call, put, takeEvery } from 'redux-saga/effects';
import { APIListResponse, APIResponse } from 'models/request';
import { User } from 'models/user';
import {
  deleteUser,
  inviteUser,
  listUsers,
  updateUser,
} from 'modules/user/actions';
import { delUser, getUsers, patchUser, postUser } from 'modules/user/services';
import handleError from 'api/handleError';

function* listUsersSaga({
  payload,
}: ReturnType<typeof listUsers.request>): Generator {
  try {
    const {
      data: { count, list },
    }: APIListResponse<User> = (yield call(
      getUsers,
      payload,
    )) as APIListResponse<User>;

    yield put(listUsers.success({ count, list }));
  } catch (err) {
    const { data } = handleError(err);

    yield put(listUsers.failure(data));
  }
}

function* inviteUserSaga({
  payload,
}: ReturnType<typeof inviteUser.request>): Generator {
  try {
    const { data }: APIResponse<User> = (yield call(
      postUser,
      payload,
    )) as APIResponse<User>;

    yield put(inviteUser.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(inviteUser.failure(data));
  }
}

function* updateUserSaga({
  payload,
}: ReturnType<typeof updateUser.request>): Generator {
  try {
    const { data }: APIResponse<User> = (yield call(
      patchUser,
      payload,
    )) as APIResponse<User>;

    yield put(updateUser.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(updateUser.failure(data));
  }
}

function* deleteUserSaga({
  payload,
}: ReturnType<typeof deleteUser.request>): Generator {
  try {
    yield call(delUser, payload);

    yield put(deleteUser.success(payload));
  } catch (err) {
    const { data } = handleError(err);

    yield put(deleteUser.failure(data));
  }
}

export default [
  takeEvery(listUsers.request, listUsersSaga),
  takeEvery(inviteUser.request, inviteUserSaga),
  takeEvery(updateUser.request, updateUserSaga),
  takeEvery(deleteUser.request, deleteUserSaga),
];
