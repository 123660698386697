import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import ReduxLogger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

export const sagaMiddleware = createSagaMiddleware();

export default (history: History) => {
  const middlewares = [routerMiddleware(history), sagaMiddleware];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(ReduxLogger);
  }

  return middlewares;
};
