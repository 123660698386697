import { createAction, createAsyncAction } from 'typesafe-actions';
import { User } from 'models/user';
import { GenericError, PaginationPayload } from 'models/request';
import { Paginated, Patch, Unregistered } from 'models/generic';

export const listUsers = createAsyncAction(
  '@@admin/USER_LIST_REQUEST',
  '@@admin/USER_LIST_REQUEST_SUCCESS',
  '@@admin/USER_LIST_REQUEST_FAILURE',
)<PaginationPayload, Paginated<User[]>, GenericError>();

export const inviteUser = createAsyncAction(
  '@@admin/INVITE_USER_REQUEST',
  '@@admin/INVITE_USER_REQUEST_SUCCESS',
  '@@admin/INVITE_USER_REQUEST_FAILURE',
)<Unregistered<User>, User, GenericError>();

export const updateUser = createAsyncAction(
  '@@admin/UPDATE_USER_REQUEST',
  '@@admin/UPDATE_USER_REQUEST_SUCCESS',
  '@@admin/UPDATE_USER_REQUEST_FAILURE',
)<Patch<User>, User, GenericError>();

export const deleteUser = createAsyncAction(
  '@@admin/DELETE_USER_REQUEST',
  '@@admin/DELETE_USER_REQUEST_SUCCESS',
  '@@admin/DELETE_USER_REQUEST_FAILURE',
)<string, string, GenericError>();

export const resetUserOperation = createAction(
  '@@admin/RESET_USER_OPERATION_STATUS',
)();
