import { post } from 'api';
import {
  ForgotPassword,
  Login,
  ResetPasswordPayload,
  SignUpPayload,
} from 'models/auth';

export const postLogin = (data: Login) => post({ data, url: `/login` });

export const postRegistration = ({ password, token }: SignUpPayload) =>
  post({ data: { password }, url: `/confirm-signup/${token}` });

export const postResetCustomerPassword = ({
  password,
  passwordConfirmation,
  token,
}: ResetPasswordPayload) =>
  post({
    data: { password, passwordConfirmation, token },
    url: '/passwords/customers/reset',
  });

export const postForgotUserPassword = (data: ForgotPassword) =>
  post({ data, url: '/passwords/users/forgot' });

export const postResetUserPassword = ({
  password,
  passwordConfirmation,
  token,
}: ResetPasswordPayload) =>
  post({
    data: { password, passwordConfirmation, token },
    url: '/passwords/users/reset',
  });
