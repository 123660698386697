import isSameObject from 'utils/comparison/isSameObject';

type Param = unknown[];

const isSameArray = (original: Param, target: Param): boolean => {
  let isEqual = true;

  if (original.length !== target.length) {
    isEqual = false;
  } else {
    for (const key in target) {
      const originalItem = original[key];
      const targetItem = target[key];

      if (Array.isArray(originalItem) && Array.isArray(targetItem)) {
        isEqual = isSameArray(originalItem, targetItem);
      } else if (
        originalItem instanceof Object &&
        targetItem instanceof Object
      ) {
        isEqual = isSameObject(
          originalItem as Record<string, unknown>,
          targetItem as Record<string, unknown>,
        );
      } else if (originalItem !== targetItem) {
        isEqual = false;
      }
    }
  }

  return isEqual;
};

export default isSameArray;
