import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useLoggedIn, useLoggedUser } from 'hooks/selectors/auth';
import { registerSocketByUser } from 'websocket';
import { NotificationsProvider } from 'hooks/notifications';

const PrivateRoute: FC<RouteProps> = (props) => {
  const isLoggedIn = useLoggedIn();

  if (!isLoggedIn) {
    return <Redirect to={'/login'} />;
  }

  const user = useLoggedUser();
  if (user) {
    registerSocketByUser(user.id);
  }

  return (
    <NotificationsProvider>
      <Route {...props} />
    </NotificationsProvider>
  );
};

export default PrivateRoute;
