import { createReducer } from 'typesafe-actions';
import { RequestStatus } from 'models/request';
import { IntegratedState } from 'models/state';
import { SellerCategory } from 'models/platform/sellerCategory';
import {
  createSellerCategory,
  deleteSellerCategory,
  listSellerCategories,
  listSellerCategoryChildren,
  resetSellerCategoryOperation,
  updateSellerCategory,
} from 'modules/platform/sellerCategory/actions';
import { TreeView } from 'models/generic';
import updateDataList from 'utils/data/updateDataList';
import filterDataList from 'utils/data/filterDataList';
import insertIntoDataList from 'utils/data/insetIntoDataList';

type State = IntegratedState<TreeView<SellerCategory>[]> & {
  childrenFetchStatus: RequestStatus;
  operationStatus: RequestStatus;
};

const initialState: State = {
  childrenFetchStatus: RequestStatus.IDLE,
  data: [],
  error: undefined,
  operationStatus: RequestStatus.IDLE,
  status: RequestStatus.IDLE,
};

export const sellerCategory = createReducer(initialState)
  .handleAction(listSellerCategories.request, (state) => ({
    ...state,
    data: [],
    error: undefined,
    status: RequestStatus.FETCHING,
  }))
  .handleAction(listSellerCategories.success, (state, action) => ({
    ...state,
    data: [...action.payload.map((item) => ({ ...item, updated: false }))],
    status: RequestStatus.SUCCESS,
  }))
  .handleAction(listSellerCategories.failure, (state, action) => ({
    ...state,
    data: [],
    error: {
      ...action.payload,
    },
    status: RequestStatus.FAILURE,
  }))
  .handleAction(listSellerCategoryChildren.request, (state) => ({
    ...state,
    childrenFetchStatus: RequestStatus.FETCHING,
  }))
  .handleAction(listSellerCategoryChildren.success, (state, action) => ({
    ...state,
    childrenFetchStatus: RequestStatus.SUCCESS,
    data: [...updateDataList(state.data, action.payload)],
  }))
  .handleAction(listSellerCategoryChildren.failure, (state, action) => ({
    ...state,
    childrenFetchStatus: RequestStatus.FAILURE,
    error: {
      ...action.payload,
    },
  }))
  .handleAction(
    [
      createSellerCategory.request,
      updateSellerCategory.request,
      deleteSellerCategory.request,
    ],
    (state) => ({
      ...state,
      operationStatus: RequestStatus.FETCHING,
    }),
  )
  .handleAction(createSellerCategory.success, (state, action) => ({
    ...state,
    data: [...insertIntoDataList(state.data, action.payload)],
    operationStatus: RequestStatus.SUCCESS,
  }))
  .handleAction(updateSellerCategory.success, (state, action) => ({
    ...state,
    data: [...updateDataList(state.data, action.payload)],
    operationStatus: RequestStatus.SUCCESS,
  }))
  .handleAction(deleteSellerCategory.success, (state, action) => ({
    ...state,
    data: [...filterDataList(state.data, action.payload)],
    operationStatus: RequestStatus.SUCCESS,
  }))
  .handleAction(
    [
      createSellerCategory.failure,
      updateSellerCategory.failure,
      deleteSellerCategory.failure,
    ],
    (state, action) => ({
      ...state,
      error: {
        ...action.payload,
      },
      operationStatus: RequestStatus.FAILURE,
    }),
  )
  .handleAction(resetSellerCategoryOperation, (state) => ({
    ...state,
    operationStatus: RequestStatus.IDLE,
  }));
