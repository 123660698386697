import { TreeView } from 'models/generic';
import { SellerCategory } from 'models/platform/sellerCategory';
import { ProductCategory } from 'models/seller/productCategory';

const filterDataList = (
  original: TreeView<SellerCategory | ProductCategory>[],
  id: string,
): TreeView<SellerCategory | ProductCategory>[] =>
  original
    .map((item) => {
      if (item.children && item.children.length) {
        item.children = filterDataList(item.children, id);
      }

      return item;
    })
    .filter((item) => item.id !== id);

export default filterDataList;
