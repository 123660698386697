import { createAction, createAsyncAction } from 'typesafe-actions';
import { GenericError } from 'models/request';
import { ProductCategory } from 'models/seller/productCategory';
import { Patch, Unregistered } from 'models/generic';

export const listProductCategories = createAsyncAction(
  '@@admin/LIST_PRODUCT_CATEGORIES',
  '@@admin/LIST_PRODUCT_CATEGORIES_SUCCESS',
  '@@admin/LIST_PRODUCT_CATEGORIES_FAILURE',
)<string, ProductCategory[], GenericError>();

export const listProductCategoryChildren = createAsyncAction(
  '@@admin/LIST_PRODUCT_CATEGORY_CHILDREN',
  '@@admin/LIST_PRODUCT_CATEGORY_CHILDREN_SUCCESS',
  '@@admin/LIST_PRODUCT_CATEGORY_CHILDREN_FAILURE',
)<string, ProductCategory, GenericError>();

export const createProductCategory = createAsyncAction(
  '@@admin/CREATE_PRODUCT_CATEGORY_REQUEST',
  '@@admin/CREATE_PRODUCT_CATEGORY_REQUEST_SUCCESS',
  '@@admin/CREATE_PRODUCT_CATEGORY_REQUEST_FAILURE',
)<Unregistered<ProductCategory>, ProductCategory, GenericError>();

export const updateProductCategory = createAsyncAction(
  '@@admin/UPDATE_PRODUCT_CATEGORY_REQUEST',
  '@@admin/UPDATE_PRODUCT_CATEGORY_REQUEST_SUCCESS',
  '@@admin/UPDATE_PRODUCT_CATEGORY_REQUEST_FAILURE',
)<Patch<ProductCategory>, ProductCategory, GenericError>();

export const deleteProductCategory = createAsyncAction(
  '@@admin/DELETE_PRODUCT_CATEGORY_REQUEST',
  '@@admin/DELETE_PRODUCT_CATEGORY_REQUEST_SUCCESS',
  '@@admin/DELETE_PRODUCT_CATEGORY_REQUEST_FAILURE',
)<string, string, GenericError>();

export const resetProductCategoryOperation = createAction(
  '@@admin/RESET_PRODUCT_CATEGORY_OPERATION_STATUS',
)();
