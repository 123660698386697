import { Customer } from 'models/customer';
import { APIListResponse, APIResponse } from 'models/request';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createCustomer,
  fetchCustomer,
  listCustomers,
  updateCustomer,
} from './actions';
import {
  getCustomer,
  getCustomers,
  patchCustomer,
  postCustomer,
} from './services';
import handleError from 'api/handleError';

function* listCustomersSaga({
  payload,
}: ReturnType<typeof listCustomers.request>): Generator {
  try {
    const { data }: APIListResponse<Customer> = (yield call(
      getCustomers,
      payload,
    )) as APIListResponse<Customer>;

    yield put(listCustomers.success({ count: data.count, list: data.list }));
  } catch (err) {
    const { data } = handleError(err);

    yield put(listCustomers.failure(data));
  }
}

function* fetchCustomerSaga({
  payload,
}: ReturnType<typeof fetchCustomer.request>): Generator {
  try {
    const { data }: APIResponse<Customer> = (yield call(
      getCustomer,
      payload,
    )) as APIResponse<Customer>;

    yield put(fetchCustomer.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(fetchCustomer.failure(data));
  }
}

function* createCustomerSaga({
  payload,
}: ReturnType<typeof createCustomer.request>): Generator {
  try {
    const { data }: APIResponse<Customer> = (yield call(
      postCustomer,
      payload,
    )) as APIResponse<Customer>;

    yield put(createCustomer.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(createCustomer.failure(data));
  }
}

function* updateCustomerSaga({
  payload,
}: ReturnType<typeof updateCustomer.request>): Generator {
  try {
    const { data }: APIResponse<Customer> = (yield call(
      patchCustomer,
      payload,
    )) as APIResponse<Customer>;

    yield put(updateCustomer.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(updateCustomer.failure(data));
  }
}

export default [
  takeEvery(listCustomers.request, listCustomersSaga),
  takeEvery(fetchCustomer.request, fetchCustomerSaga),
  takeEvery(createCustomer.request, createCustomerSaga),
  takeEvery(updateCustomer.request, updateCustomerSaga),
];
