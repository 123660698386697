import React, { FC } from 'react';
import { Box, CircularProgress, Fade } from '@material-ui/core';
import useLoadingOverlayStyles from './useLoadingOverlayStyles';

const LoadingOverlay: FC = () => {
  const classes = useLoadingOverlayStyles();

  return (
    <Fade in={true}>
      <Box className={classes.box}>
        <CircularProgress color={'inherit'} />
      </Box>
    </Fade>
  );
};

export default LoadingOverlay;
