import { Paginated, Unregistered } from 'models/generic';
import { GenericError, PaginationPayload } from 'models/request';
import { Seller, SellerPayload } from 'models/seller';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { SellerDeliveringPayload, SellerWorkingHoursPayload } from './types';

export const listSellers = createAsyncAction(
  '@@admin/SELLER_LIST_REQUEST',
  '@@admin/SELLER_LIST_REQUEST_SUCCESS',
  '@@admin/SELLER_LIST_REQUEST_FAILURE',
)<PaginationPayload, Paginated<Seller[]>, GenericError>();

export const fetchSeller = createAsyncAction(
  '@@admin/FETCH_SELLER_REQUEST',
  '@@admin/FETCH_SELLER_REQUEST_SUCCESS',
  '@@admin/FETCH_SELLER_REQUEST_FAILURE',
)<string, Seller, GenericError>();

export const createSeller = createAsyncAction(
  '@@admin/CREATE_SELLER_REQUEST',
  '@@admin/CREATE_SELLER_REQUEST_SUCCESS',
  '@@admin/CREATE_SELLER_REQUEST_FAILURE',
)<Unregistered<Seller>, Seller, GenericError>();

export const updateSeller = createAsyncAction(
  '@@admin/UPDATE_SELLER_REQUEST',
  '@@admin/UPDATE_SELLER_REQUEST_SUCCESS',
  '@@admin/UPDATE_SELLER_REQUEST_FAILURE',
)<SellerPayload, Seller, GenericError>();

export const updateSellerWorkingHours = createAsyncAction(
  '@@admin/UPDATE_SELLER_WORKING_HOURS_REQUEST',
  '@@admin/UPDATE_SELLER_WORKING_HOURS_REQUEST_SUCCESS',
  '@@admin/UPDATE_SELLER_WORKING_HOURS_REQUEST_FAILURE',
)<SellerWorkingHoursPayload, Seller, GenericError>();

export const updateSellerIsDeliverable = createAsyncAction(
  '@@admin/UPDATE_SELLER_IS_DELIVERABLE_REQUEST',
  '@@admin/UPDATE_SELLER_IS_DELIVERABLE_REQUEST_SUCCESS',
  '@@admin/UPDATE_SELLER_IS_DELIVERABLE_REQUEST_FAILURE',
)<SellerDeliveringPayload, Seller, GenericError>();

export const deleteSeller = createAsyncAction(
  '@@admin/DELETE_SELLER_REQUEST',
  '@@admin/DELETE_SELLER_REQUEST_SUCCESS',
  '@@admin/DELETE_SELLER_REQUEST_FAILURE',
)<string, string, GenericError>();

export const resetSellerOperation = createAction(
  '@@admin/RESET_SELLER_OPERATION_STATUS',
)();

export const resetFetchedSellerData = createAction(
  '@@admin/RESET_FETCHED_SELLER_DATA',
)();
