import { APIResponse } from 'models/request';
import { call, put, takeEvery } from 'redux-saga/effects';
import handleError from 'api/handleError';
import { SellerCategory } from 'models/platform/sellerCategory';
import {
  createSellerCategory,
  deleteSellerCategory,
  listSellerCategories,
  listSellerCategoryChildren,
  updateSellerCategory,
} from './actions';
import {
  delSellerCategory,
  getSellerCategoriesByPlatform,
  getSellerCategory,
  patchSellerCategory,
  postSellerCategory,
} from './services';

function* listSellerCategoriesSaga({
  payload,
}: ReturnType<typeof listSellerCategories.request>): Generator {
  try {
    const { data }: APIResponse<SellerCategory[]> = (yield call(
      getSellerCategoriesByPlatform,
      payload,
    )) as APIResponse<SellerCategory[]>;

    yield put(listSellerCategories.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(listSellerCategories.failure(data));
  }
}

function* listSellerCategoryChildrenSaga({
  payload,
}: ReturnType<typeof listSellerCategoryChildren.request>): Generator {
  try {
    const { data }: APIResponse<SellerCategory> = (yield call(
      getSellerCategory,
      payload,
    )) as APIResponse<SellerCategory>;

    yield put(listSellerCategoryChildren.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(listSellerCategoryChildren.failure(data));
  }
}

function* createSellerCategorySaga({
  payload,
}: ReturnType<typeof createSellerCategory.request>): Generator {
  try {
    const { data }: APIResponse<SellerCategory> = (yield call(
      postSellerCategory,
      payload,
    )) as APIResponse<SellerCategory>;

    yield put(createSellerCategory.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(createSellerCategory.failure(data));
  }
}

function* updateSellerCategorySaga({
  payload,
}: ReturnType<typeof updateSellerCategory.request>): Generator {
  try {
    const { data }: APIResponse<SellerCategory> = (yield call(
      patchSellerCategory,
      payload,
    )) as APIResponse<SellerCategory>;

    yield put(updateSellerCategory.success(data));
  } catch (err) {
    const { data } = handleError(err);

    yield put(updateSellerCategory.failure(data));
  }
}

function* deleteSellerCategorySaga({
  payload,
}: ReturnType<typeof deleteSellerCategory.request>): Generator {
  try {
    yield call(delSellerCategory, payload);

    yield put(deleteSellerCategory.success(payload));
  } catch (err) {
    const { data } = handleError(err);

    yield put(deleteSellerCategory.failure(data));
  }
}

export default [
  takeEvery(listSellerCategories.request, listSellerCategoriesSaga),
  takeEvery(listSellerCategoryChildren.request, listSellerCategoryChildrenSaga),
  takeEvery(createSellerCategory.request, createSellerCategorySaga),
  takeEvery(updateSellerCategory.request, updateSellerCategorySaga),
  takeEvery(deleteSellerCategory.request, deleteSellerCategorySaga),
];
