import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(
  (theme) =>
    createStyles({
      box: {
        alignItems: 'center',
        backgroundColor: `${theme.palette.primary.main}70`,
        bottom: 0,
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
      },
    }),
  { index: 1 },
);
